@import '../../../../../scss//index.scss';
.btn-download {
    background-color: $background-blue-dark;
    border-radius: 4px;
    width: 200px;
    height: 40px;
    transition: 0.3s;
    font-size: 16px;
    color: white;
    margin-left: 20px;

    &:hover {
        background-color: lighten($color: $background-blue-dark, $amount: 10);
        color: white;
        border-color: $background-blue-dark;
    }
}
