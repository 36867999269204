@import '../../../scss/index.scss';
.chart-amount {
    h4 {
        font-size: 20px;
    }
    &__dropdown {
        text-align: right;
        padding: 20px 30px;
    }

    &__title {
        padding: 20px 30px;
        h3{
            font-weight: 500;
            font-family: 'Roboto', sans-serif;
            margin-bottom: 0px;
            color: $font-gray;
            font-size: 20px;
            text-transform: uppercase;
        }
    }

}

.chart-amount-select {
    .ant-dropdown-menu-item {
        color: $primary-color;
        border: 1px solid #f5f5f5;
        margin: 5px 10px;
        background-color: #f5f5f5;
        border-radius: 10px;
        padding: 3px 12px;

        &:hover {
            color: $font-light;
            background-color: $primary-color;
        }
    }
    .ant-dropdown-menu-item.other-range{
        &:hover {
            background-color: #f5f5f5;
        }
    }

    .ant-dropdown-menu-item-selected {
        color: $font-light;
        background-color: $primary-color;
    }
}
